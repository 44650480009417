import { CloudinaryImageT } from "../../types";
import { getAxiosClient } from "../client";
import {
  deleteParaglidingPageBannerImageEndpoint,
  deleteParaglidingPageCarouselImageEndpoint,
  getParaglidingPageEndpoint,
  postParaglidingPageBannerImageEndpoint,
  postParaglidingPageCarouselImageEndpoint,
  putParaglidingLocationEndpoint,
  putParaglidingPageBannerEndpoint,
  putParaglidingPageCarouselImageEndpoint,
  putParaglidingPageFormSectionEndpoint,
  putParaglidingPageMainSectionEndpoint,
} from "../endpoints/paragliding";
import { makeApiRequest } from "../requests";
import { ApiResponse, GetParaglidingPageResT, LocationInfoT, messageResT } from "../responses";

// ========== GET ========== //

export const getParaglidingPage = (): Promise<
  ApiResponse<GetParaglidingPageResT>
> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getParaglidingPageEndpoint()
  );
  const result = makeApiRequest<GetParaglidingPageResT>(httpRequest);

  return result;
};

// ========== POST ========== //

export const createParaglidingPageBannerImage = (data: {
  image: CloudinaryImageT;
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postParaglidingPageBannerImageEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const createParaglidingPageCarouselImage = (data: {
  images: CloudinaryImageT;
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postParaglidingPageCarouselImageEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== PUT ========== //

export const updateParaglidingPageBanner = (
  data: {
    title?: string;
    subtitle?: string;
    image?: CloudinaryImageT;
  },
  imgId?: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putParaglidingPageBannerEndpoint(imgId),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateParaglidingMainSection = (
  data: {
    title?: string;
    subtitle?: string;
    image?: CloudinaryImageT;
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putParaglidingPageMainSectionEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateParaglidingPageFormSection = (
  data: {
    title?: string;
    subtitle?: string;
    description?: string;
    image?: CloudinaryImageT;
    locationTitle?: string;
    warningTitle?: string;
    warning?: string;
  },
  itemId?: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putParaglidingPageFormSectionEndpoint(itemId),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateParaglidingLocation = (data:Omit<LocationInfoT,"_id"> ,id: string): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if(!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putParaglidingLocationEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result
}

export const updateParaglidingPageCarousel = (
  data: {
    image: CloudinaryImageT;
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putParaglidingPageCarouselImageEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== DELETE ========== //

export const deleteParaglidingPageBannerImage = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteParaglidingPageBannerImageEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const deleteParaglidingPageCarouselImage = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteParaglidingPageCarouselImageEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};
