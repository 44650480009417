import { Flex } from "antd";
import warning from "antd/es/_util/warning";
import { title } from "process";
import React, { useState } from "react";
import { ItemT, messageResT } from "src/api";
import { TransferFormType } from "src/api/responses/otherActivitiesResponses";
import MediaCard from "src/components/MediaCard/MediaCard";
import MediaEditModal from "src/components/MediaEditModal/MediaEditModal";
import InfoItemsMediaCard from "src/components/SkiLessonsMediaCard/InfoItemsMediaCard";
import { useApiRequest } from "src/hooks/useApiRequests";
import useMessages from "src/hooks/useMessages";
import { cloudinaryUpload } from "src/lib/cloudinaryUpload";
import { TransferFormProps } from "src/types/component-types/otherActivitiesProps";

const TransferFormSection: React.FC<TransferFormProps> = ({
  data,
  updateService,
  getData,
}) => {
  const {
    title,
    gudauriToGergetiExcursionDesc,
    gudauriToTbilisiAirportDesc,
    gudauriToTbilisiTransferDesc,
    tbilisiAirportToGudauriDesc,
    tbilisiFreedomSquareToGudauriDesc,
    gudauriToKazbegiTourDesc,
    gudauriToKhadaExplorationDesc,
    transferFromTbilisiToKazbegiDesc,
    fullDayTourTbilisiToKazbegiDesc,
    items,
  } = data;
  const { error, success, contextHolder } = useMessages();
  const [editForm, setEditForm] =
    useState<Omit<TransferFormType, "items">>(data);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [itemEditValue, setItemEditValue] = useState<ItemT>();
  const [loading, setLoading] = useState<boolean>(false);

  const [, makeUpdateRequest] = useApiRequest<messageResT>();

  const showEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleOkEdit = () => {
    if (editForm) {
      setLoading(true);
      try {
        makeUpdateRequest(updateService(editForm), (e) => {
          setLoading(false);
          setIsEditModalOpen(false);
          success(e.message);
          getData();
        });
      } catch (err) {
        setLoading(false);
        error("Something went wrong");
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    setEditForm((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleItemEdit = (id: string) => {
    const filteredData = items.find((item) => item._id === id);

    setItemEditValue((prev) => {
      if (prev === filteredData) return undefined;

      return filteredData;
    });
  };

  const handleItemFieldChange = async (fieldName: string, value: any) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }
    setItemEditValue(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as ItemT)
    );
    setLoading(false);
  };

  const onItemEditConfirm = () => {
    try {
      setLoading(true);
      makeUpdateRequest(
        updateService(
          {
            description: itemEditValue?.description,
            image: itemEditValue?.image,
          },
          itemEditValue?._id
        ),
        (e) => {
          success(e.message);
          setItemEditValue(undefined);
          getData();
          setLoading(false);
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={24}>
          <Flex vertical gap={12}>
            <h2>Transfer and tours</h2>
            <MediaCard
              data={{
                _id: "",
                title: title,
                gudauriToGergetiExcursionDesc,
                gudauriToTbilisiAirportDesc,
                gudauriToTbilisiTransferDesc,
                tbilisiAirportToGudauriDesc,
                tbilisiFreedomSquareToGudauriDesc,
                gudauriToKazbegiTourDesc,
                gudauriToKhadaExplorationDesc,
                transferFromTbilisiToKazbegiDesc,
                fullDayTourTbilisiToKazbegiDesc,
              }}
              onEdit={showEditModal}
            />
          </Flex>

          <InfoItemsMediaCard
            data={items}
            itemEditValue={itemEditValue}
            loading={loading}
            onEdit={handleItemEdit}
            handleFieldChange={handleItemFieldChange}
            handleUpdate={onItemEditConfirm}
          />
        </Flex>
        <MediaEditModal
          isMediaEditModalOpen={isEditModalOpen}
          handleOk={handleOkEdit}
          handleCancel={handleCancelEdit}
          handleFieldChange={handleFieldChange}
          mediaDetails={editForm}
          isLoading={loading}
          containsImage={false}
          useTextAreaForSubtitleEdit
        />
      </section>
    </>
  );
};

export default TransferFormSection;
