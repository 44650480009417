import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DatesManagementControllerProps } from "src/types";
import { messageResT } from "src/api/responses";
import { useApiRequest } from "src/hooks/useApiRequests";
import useMessages from "src/hooks/useMessages";
import { Button, DatePicker, DatePickerProps, Flex } from "antd";
import { type } from "os";
import { updateDatesManagementParagliding } from "src/api/services";

const { RangePicker } = DatePicker;

const DatesManagementController: React.FC<DatesManagementControllerProps> = ({
  _id,
  name,
  startDate,
  endDate,
  dayOff,
  updateService,
  getData,
}) => {
  const [isEditStart, setIsEditStart] = useState<boolean>(false);
  const [isEditEnd, setIsEditEnd] = useState<boolean>(false);
  const [isEditDayOff, setIsEditDayOff] = useState<boolean>(false);
  const [editStartDate, setEditStartDate] = useState<Dayjs | null>(
    dayjs(startDate)
  );
  const [editEndDate, setEditEndDate] = useState<Dayjs | null>(dayjs(endDate));
  const [editDayOff, setEditDayOff] = useState<
    [Dayjs | null, Dayjs | null] | null
  >(
    dayOff && dayOff.length === 2 ? [dayjs(dayOff[0]), dayjs(dayOff[1])] : null
  );

  const { success, error, contextHolder } = useMessages();
  const [, makeRequest] = useApiRequest<messageResT>();

  const handleStartDateChange: DatePickerProps["onChange"] = (date) => {
    setEditStartDate(date);
  };

  const handleEndDateChange: DatePickerProps["onChange"] = (date) => {
    setEditEndDate(date);
  };

  const handleDayOffChange = (dates: [Dayjs, Dayjs] | null) => {
    if (!dates) {
      setEditDayOff(null); // Set to null if no date range is selected
    } else {
      setEditDayOff(dates); // Update with selected range
    }
  };

  const handleStartDateUpdateConfirm = () => {
    try {
      makeRequest(
        updateService(
          {
            startDate: editStartDate ? editStartDate.format("YYYY-MM-DD") : "",
          },
          _id
        ),
        (e) => {
          success(e.message);
          getData();
          setIsEditStart(false);
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleEndDateUpdateConfirm = () => {
    try {
      makeRequest(
        updateService(
          {
            endDate: editEndDate ? editEndDate.format("YYYY-MM-DD") : "",
          },
          _id
        ),
        (e) => {
          success(e.message);
          getData();
        }
      );
    } catch (err) {
      error("Something went wrong");
    } finally {
      setIsEditEnd(false);
    }
  };

  const handleDayOffUpdateConfirm = () => {
    try {
      // Fallback for empty or invalid values
      const updatedDayOff =
        editDayOff && editDayOff[0] && editDayOff[1]
          ? [
              editDayOff[0].format("YYYY-MM-DD"),
              editDayOff[1].format("YYYY-MM-DD"),
            ]
          : null;

      makeRequest(
        updateService(
          {
            dayOff: updatedDayOff,
          },
          _id
        ),
        (e) => {
          success(e.message);
          getData();
        }
      );
    } catch (err) {
      error("Something went wrong");
    } finally {
      setIsEditDayOff(false);
    }
  };

  const dayOffDateRenderer = () => {
    if (isEditDayOff) return;

    if (!dayOff) return "Choose date";

    const [start, end] = dayOff;

    return `${dayjs(start).format("DD-MM-YYYY")} ${dayjs(end).format(
      "DD-MM-YYYY"
    )}`;
  };

  return (
    <>
      {contextHolder}
      <Flex vertical gap={24}>
        <h3>{name}</h3>
        <Flex gap={8} align="center">
          <p>
            Start Date: {!isEditStart && dayjs(startDate).format("DD-MM-YYYY")}
          </p>
          {isEditStart && (
            <DatePicker
              onChange={handleStartDateChange}
              format={"DD-MM-YYYY"}
              value={editStartDate}
            />
          )}
          <Flex align="center" gap={8}>
            <Button onClick={() => setIsEditStart(!isEditStart)}>
              {isEditStart ? "Cancel" : "Edit"}
            </Button>
            {isEditStart && (
              <Button type="primary" onClick={handleStartDateUpdateConfirm}>
                Confirm
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex gap={8} align="center">
          <p>
            End Date:{" "}
            {!isEditEnd && endDate !== ""
              ? dayjs(endDate).format("DD-MM-YYYY")
              : "Choose Date"}
          </p>
          {isEditEnd && (
            <DatePicker
              onChange={handleEndDateChange}
              format={"DD-MM-YYYY"}
              value={editEndDate}
            />
          )}
          <Flex align="center" gap={8}>
            <Button onClick={() => setIsEditEnd(!isEditEnd)}>
              {isEditEnd ? "Cancel" : "Edit"}
            </Button>
            {isEditEnd && (
              <Button type="primary" onClick={handleEndDateUpdateConfirm}>
                Confirm
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex gap={8} align="center">
          <p>Day off: {dayOffDateRenderer()}</p>
          {isEditDayOff && (
            <RangePicker
              value={editDayOff}
              onChange={handleDayOffChange as any}
              format={"DD-MM-YYYY"}
            />
          )}
          <Flex align="center" gap={8}>
            <Button onClick={() => setIsEditDayOff(!isEditDayOff)}>
              {isEditDayOff ? "Cancel" : "Edit"}
            </Button>
            {isEditDayOff && (
              <Button type="primary" onClick={handleDayOffUpdateConfirm}>
                Confirm
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default DatesManagementController;
