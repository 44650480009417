import { useEffect, useRef, useState } from "react";
import { Button, InputRef, Space, Table, TableProps } from "antd";
import { ColumnType, FilterValue } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import { TableWrapper } from "../TableWrapper";
import { skiSchoolColumns } from "./TableColumns/TableColumns";
import { ColumnSearch } from "../ColumnSearch";
import { deleteModal } from "../DeleteModal";
import { ActionsOnEditModal } from "../ActionsOnEditModal";
import { EditModal } from "./EditModal";
import { successEditModal } from "../SuccessEditModal";
import { successDeleteModal } from "../SuccessDeleteModal";
import { SkiSchoolT, DataIndex } from "./skiTypes/skiTypes";
import { ActivitiesSkeleton } from "../ActivitiesSkeleton";
import classes from "./skiSchool.module.css";

const SkiSchool = () => {
  const [skiSchoolInfo, setSkiSchoolInfo] = useState<SkiSchoolT[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // edit modal related
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editOrder, setEditOrder] = useState({
    _id: "",
    name: "",
    number: "",
    email: "",
    age: "",
    lessonType: "",
    groupMembers: "",
    additional: "",
    activityType: "",
    experience: "",
    hours: "",
    fromDate: "",
    toDate: "",
    date: "",
    fromHour: "",
    currency: {
      usd: 0,
      gel: 0,
    },
    location: "",
  });

  const showModal = (id: string) => {
    const editableOrder = skiSchoolInfo.find((order: any) => order._id === id);
    setIsEditModalOpen(true);
    editableOrder !== undefined && setEditOrder(editableOrder);
  };

  const handleOk = () => {
    setIsEditModalOpen(false);
    handleEditOrder(editOrder._id);
  };

  const handleCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    setEditOrder((prevEditOrder) => ({
      ...prevEditOrder,
      [fieldName]: value,
    }));
  };

  // column search related
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleChange: TableProps<SkiSchoolT>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setFilteredInfo(filters);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<SkiSchoolT> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <ColumnSearch
        ref={searchInput}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() =>
          handleSearch(selectedKeys as string[], confirm, dataIndex)
        }
        onSearchClick={() =>
          handleSearch(selectedKeys as string[], confirm, dataIndex)
        }
        onResetClick={() => {
          clearFilters && handleReset(clearFilters);
          handleSearch(selectedKeys as string[], confirm, dataIndex);
        }}
      />
    ),

    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        style={{ fontSize: 14, color: filtered ? "#1677ff" : "#fff" }}
      />
    ),

    onFilter: (value: boolean | React.Key, record: SkiSchoolT) => {
      const dataIndexValue = record[dataIndex]?.toString();

      if (dataIndexValue === undefined) {
        return false;
      }

      const filterValue = value as string;
      return dataIndexValue.toLowerCase().includes(filterValue.toLowerCase());
    },

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = skiSchoolColumns.map((column: ColumnType<SkiSchoolT>) => {
    const columnDataIndex = column.dataIndex as keyof SkiSchoolT;
    const columnFilteredValue = filteredInfo[columnDataIndex] || null;

    return {
      ...column,
      ...getColumnSearchProps(columnDataIndex),
      filteredValue: columnFilteredValue,
    };
  });

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/skischool`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data: SkiSchoolT[]) => {
        setSkiSchoolInfo(data.reverse());
        setLoading(false);
      })
      .catch((error: Error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <ActivitiesSkeleton />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleEditOrder = (id: string) => {
    setLoading(true);
    const updatedData = { ...editOrder };

    fetch(`${process.env.REACT_APP_API_URL}/skischool/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to edit order");
        }
        successEditModal();
        return response.json();
      })
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  const handleDeleteOrder = (id: string) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/skischool/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete order");
        }
        successDeleteModal();
        fetchData();
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  const showDeleteConfirm = (id: string) => {
    deleteModal(() => handleDeleteOrder(id));
  };

  const tableData: SkiSchoolT[] = skiSchoolInfo.map((item) => ({
    key: item._id,
    _id: item._id,
    name: item.name,
    number: item.number,
    email: item.email,
    age: item.age ? item.age : "-",
    lessonType: item.lessonType,
    groupMembers: item.groupMembers ? item.groupMembers : "1",
    additional: item.additional,
    activityType: item.activityType,
    experience: item.experience,
    hours: item.hours,
    fromDate: item.fromDate,
    toDate: item.toDate,
    date: `${item.fromDate} - ${item.toDate}`,
    fromHour: item.fromHour,
    currency: item.currency,
    location: item.location,
    actions: (
      <ActionsOnEditModal
        onEditClick={() => showModal(item._id)}
        onDeleteClick={() => showDeleteConfirm(item._id)}
      />
    ),
  }));

  const handleResetAll = () => {
    setFilteredInfo({});
  };

  return (
    <div className={classes.skiSchool}>
      <h2>Ski School Information</h2>
      <Space>
        <Button onClick={handleResetAll}>Reset all</Button>
      </Space>
      <TableWrapper>
        <Table
          bordered
          scroll={{ x: "calc(1500px + 70%)" }}
          pagination={{ pageSize: 15 }}
          columns={columns}
          dataSource={tableData}
          onChange={handleChange}
        />
      </TableWrapper>
      <EditModal
        isEditModalOpen={isEditModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        orderDetails={editOrder}
        handleFieldChange={handleFieldChange}
      />
    </div>
  );
};

export default SkiSchool;
