import { CloudinaryImageT } from "../../types";
import { getAxiosClient } from "../client";
import {
  deleteContactPageBannerImageEndpoint,
  deleteContactPageCarouselImageEndpoint,
  deleteContactPageFAQQuestionEndpoint,
  deleteContactPageFAQTitleEndpoint,
  getContactPageEndpoint,
  postContactPageBannerImageEndpoint,
  postContactPageCarouselImageEndpoint,
  postContactPageFAQQuestionEndpoint,
  postContactPageFAQTitleEndpoint,
  putContactPageBanner,
  putContactPageBannerImageEndpoint,
  putContactPageCarouselImageEndpoint,
  putContactPageFAQQuestionEndpoint,
  putContactPageFAQTitleEndpoint,
} from "../endpoints";
import { makeApiRequest } from "../requests";
import { ApiResponse, messageResT } from "../responses";
import { GetContactPageResT } from "../responses/contactPageResponses";

// ========== GET ========== //

export const getContactPage = (): Promise<ApiResponse<GetContactPageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getContactPageEndpoint()
  );
  const result = makeApiRequest<GetContactPageResT>(httpRequest);

  return result;
};

// ========== POST ========== //
export const createContactPageImageBanner = (data: {
  images: CloudinaryImageT | CloudinaryImageT[];
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postContactPageBannerImageEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const createContactPageFaqTitle = (data: {
  title: string;
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postContactPageFAQTitleEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const createContactPageFaqQuestion = (data: {
  question: string;
  answer: string;
  titleId: string;
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postContactPageFAQQuestionEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const createContactPageCarouselImage = (data: {
  images: CloudinaryImageT | CloudinaryImageT[];
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postContactPageCarouselImageEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== PUT ========== //

export const updateContactPageBanner = (data: {
  title?: string;
  email?: string;
  workDays?: string;
  phoneNumber?: string;
  location?: string;
  locationLink?: string;
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putContactPageBanner(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result
}

export const updateContactPageImageBanner = (
  data: {
    image: CloudinaryImageT;
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putContactPageBannerImageEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateContactPageFaqTitle = (
  { title }: { title: string },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putContactPageFAQTitleEndpoint(id),
    { title }
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateContactPageFaqQuestion = (
  data: {
    question?: string;
    answer?: string;
    titleId?: string;
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putContactPageFAQQuestionEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateContactPageCarouselImage = (
  data: {
    image: CloudinaryImageT;
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putContactPageCarouselImageEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== DELETE ========== //

export const deleteContactPageImageBanner = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteContactPageBannerImageEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const deleteContactPageFaqTitle = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteContactPageFAQTitleEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const deleteContactPageFaqQuestion = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteContactPageFAQQuestionEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const deleteContactPageCarouselImage = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteContactPageCarouselImageEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};
