import { Flex, Input, Modal } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import classes from "./mediaEditModal.module.css";
import { useRef, useState } from "react";

type MediaEditModalProps = {
  isMediaEditModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  handleFieldChange: (dataIndex: string, value: any) => void;
  mediaDetails:
    | {
        title?: string;
        subtitle?: string;
        description?: string;
        locationTitle?: string;
        warningTitle?: string;
        warning?: string;
        location?: string;
        locationLink?: string;
        email?: string;
        workDays?: number;
        phoneNumber?: string;
        gudauriToTbilisiTransferDesc?: string;
        gudauriToTbilisiAirportDesc?: string;
        tbilisiAirportToGudauriDesc?: string;
        tbilisiFreedomSquareToGudauriDesc?: string;
        gudauriToKazbegiTourDesc?: string;
        gudauriToGergetiExcursionDesc?: string;
        gudauriToKhadaExplorationDesc?: string;
        transferFromTbilisiToKazbegiDesc?: string;
        fullDayTourTbilisiToKazbegiDesc?: string;
      }
    | undefined;
  isLoading: boolean;
  containsImage?: boolean;
  useTextAreaForSubtitleEdit?: boolean;
};

const MediaEditModal: React.FC<MediaEditModalProps> = ({
  isMediaEditModalOpen,
  handleOk,
  handleCancel,
  handleFieldChange,
  mediaDetails,
  isLoading,
  containsImage = true,
  useTextAreaForSubtitleEdit = false,
}) => {
  const [prevImage, setPrevImage] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  if (!mediaDetails) return <></>;
  const {
    title,
    subtitle,
    description,
    warningTitle,
    warning,
    locationTitle,
    location,
    locationLink,
    email,
    workDays,
    phoneNumber,
    gudauriToGergetiExcursionDesc,
    gudauriToTbilisiAirportDesc,
    gudauriToTbilisiTransferDesc,
    tbilisiAirportToGudauriDesc,
    tbilisiFreedomSquareToGudauriDesc,
    gudauriToKazbegiTourDesc,
    gudauriToKhadaExplorationDesc,
    transferFromTbilisiToKazbegiDesc,
    fullDayTourTbilisiToKazbegiDesc,
  } = mediaDetails;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setPrevImage(URL.createObjectURL(selectedFile));
      handleFieldChange("image", selectedFile);
    }
  };

  const onOk = () => {
    handleOk();
    setPrevImage(null);
    if (inputRef.current) inputRef.current.value = "";
  };

  const onCancel = () => {
    setPrevImage(null);
    handleCancel();
  };

  return (
    <Modal
      title="Edit Details"
      open={isMediaEditModalOpen}
      onOk={onOk}
      onCancel={onCancel}
      className={classes.editModal}
      okButtonProps={{ loading: isLoading }}
      centered
    >
      <Flex vertical gap={12} className={classes.body}>
        {containsImage && (
          <Flex vertical gap={24}>
            {prevImage && (
              <img className={classes.prevImg} src={prevImage} alt="img" />
            )}
            <input type="file" ref={inputRef} onChange={handleImageChange} />
          </Flex>
        )}
        {title !== undefined && (
          <div className={classes.content}>
            <p>Title: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("title", value);
                },
              }}
            >
              {title}
            </Paragraph>
          </div>
        )}

        {subtitle && (
          <div className={classes.content}>
            <p>Subtitle: </p>
            {useTextAreaForSubtitleEdit ? (
              <Input.TextArea
                value={subtitle}
                onChange={(e) => handleFieldChange("subtitle", e.target.value)}
                autoSize={{ minRows: 3, maxRows: 15 }}
              />
            ) : (
              <Paragraph
                style={{ margin: 0 }}
                editable={{
                  onChange: (value) => {
                    handleFieldChange("subtitle", value);
                  },
                }}
              >
                {subtitle}
              </Paragraph>
            )}
          </div>
        )}

        {description && (
          <div className={classes.content}>
            <p>Description: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("description", value);
                },
              }}
            >
              {description}
            </Paragraph>
          </div>
        )}

        {email && (
          <div className={classes.content}>
            <p>Email: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("email", value);
                },
              }}
            >
              {email}
            </Paragraph>
          </div>
        )}
        {tbilisiAirportToGudauriDesc && (
          <div className={classes.content}>
            <p>Tbilisi Airport to Gudauri:</p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("tbilisiAirportToGudauriDesc", value);
                },
              }}
            >
              {tbilisiAirportToGudauriDesc}
            </Paragraph>
          </div>
        )}

        {tbilisiFreedomSquareToGudauriDesc && (
          <div className={classes.content}>
            <p>Tbilisi Freedom Square to Gudauri:</p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("tbilisiFreedomSquareToGudauriDesc", value);
                },
              }}
            >
              {tbilisiFreedomSquareToGudauriDesc}
            </Paragraph>
          </div>
        )}

        {transferFromTbilisiToKazbegiDesc && (
          <div className={classes.content}>
            <p>Transfer from Tbilisi to Kazbegi:</p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("transferFromTbilisiToKazbegiDesc", value);
                },
              }}
            >
              {transferFromTbilisiToKazbegiDesc}
            </Paragraph>
          </div>
        )}

        {gudauriToTbilisiTransferDesc && (
          <div className={classes.content}>
            <p>Gudauri to Tbilisi Transfer:</p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("gudauriToTbilisiTransferDesc", value);
                },
              }}
            >
              {gudauriToTbilisiTransferDesc}
            </Paragraph>
          </div>
        )}

        {gudauriToTbilisiAirportDesc && (
          <div className={classes.content}>
            <p>Gudauri to Tbilisi Airport:</p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("gudauriToTbilisiAirportDesc", value);
                },
              }}
            >
              {gudauriToTbilisiAirportDesc}
            </Paragraph>
          </div>
        )}

        {gudauriToGergetiExcursionDesc && (
          <div className={classes.content}>
            <p>Gudauri to Gergeti Excursion:</p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("gudauriToGergetiExcursionDesc", value);
                },
              }}
            >
              {gudauriToGergetiExcursionDesc}
            </Paragraph>
          </div>
        )}

        {gudauriToKazbegiTourDesc && (
          <div className={classes.content}>
            <p>Gudauri to Kazbegi Tour:</p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("gudauriToKazbegiTourDesc", value);
                },
              }}
            >
              {gudauriToKazbegiTourDesc}
            </Paragraph>
          </div>
        )}

        {gudauriToKhadaExplorationDesc && (
          <div className={classes.content}>
            <p>Gudauri to Khada Exploration:</p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("gudauriToKhadaExplorationDesc", value);
                },
              }}
            >
              {gudauriToKhadaExplorationDesc}
            </Paragraph>
          </div>
        )}

        {fullDayTourTbilisiToKazbegiDesc && (
          <div className={classes.content}>
            <p>Full Day Tour Tbilisi to Kazbegi:</p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("fullDayTourTbilisiToKazbegiDesc", value);
                },
              }}
            >
              {fullDayTourTbilisiToKazbegiDesc}
            </Paragraph>
          </div>
        )}

        {warningTitle && (
          <div className={classes.content}>
            <p>Warning Title: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("warningTitle", value);
                },
              }}
            >
              {warningTitle}
            </Paragraph>
          </div>
        )}

        {warning && (
          <div className={classes.content}>
            <p>Warning: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("warning", value);
                },
              }}
            >
              {warning}
            </Paragraph>
          </div>
        )}

        {locationTitle && (
          <div className={classes.content}>
            <p>Location Title: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("locationTitle", value);
                },
              }}
            >
              {locationTitle}
            </Paragraph>
          </div>
        )}

        {workDays && (
          <div className={classes.content}>
            <p>Work Days: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("workDays", value);
                },
              }}
            >
              {workDays}
            </Paragraph>
          </div>
        )}

        {phoneNumber && (
          <div className={classes.content}>
            <p>Content: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("phoneNumber", value);
                },
              }}
            >
              {phoneNumber}
            </Paragraph>
          </div>
        )}

        {location && (
          <div className={classes.content}>
            <p>Location: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("location", value);
                },
              }}
            >
              {location}
            </Paragraph>
            {locationLink && (
              <>
                <p>LocationLink</p>
                <Paragraph
                  style={{ margin: 0 }}
                  editable={{
                    onChange: (value) => {
                      handleFieldChange("locationLink", value);
                    },
                  }}
                >
                  {locationLink}
                </Paragraph>
              </>
            )}
          </div>
        )}
      </Flex>
    </Modal>
  );
};

export default MediaEditModal;
