import { GroupLessonReqT, IndividualLessonReqT } from "../../../api/requests";
import { messageResT } from "../../../api/responses";
import {
  updatePriceManagementGroupSki,
  updatePriceManagementGroupSnowboard,
} from "../../../api/services/priceManagementServices";
import { useApiRequest } from "../../../hooks/useApiRequests";
import useMessages from "../../../hooks/useMessages";
import { PriceUpdateGroupComponentProps } from "../../../types";
import { Flex, Input, Button } from "antd";
import React, { useState } from "react";

const GroupLessonPrices: React.FC<PriceUpdateGroupComponentProps> = ({
  _id,
  two_hours,
  three_hours,
  four_hours,
  full_day,
  getData,
  title,
  isSki,
}) => {
  const [editValues, setEditValues] = useState<GroupLessonReqT>({
    _id,
    two_hours,
    three_hours,
    four_hours,
    full_day,
  });
  const [isEdit, setIsEdit] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(name, value);
    setEditValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateConfirm = () => {
    try {
      setLoading(true);
      makeRequest(
        (isSki
          ? updatePriceManagementGroupSki
          : updatePriceManagementGroupSnowboard)(
          {
            two_hours: Number(editValues.two_hours),
            three_hours: Number(editValues.three_hours),
            four_hours: Number(editValues.four_hours),
            full_day: Number(editValues.full_day),
          },
          editValues._id
        ),
        (e) => {
          success(e.message);
          getData();
          setLoading(false);
          setIsEdit([]);
        }
      );
    } catch (err) {
      error("Something went wrong");
    } finally {
      setLoading(false);
      setIsEdit([]);
    }
  };

  return (
    <>
      {contextHolder}
      <Flex gap={24} vertical align="flex-start">
        <Flex gap={24} vertical>
          <h2>Group {title}</h2>

          <Flex gap={12} align="center">
            <h3>2 Hours:</h3>
            {isEdit.includes("two") ? (
              <Input
                value={editValues.two_hours}
                onChange={handleChange}
                name="two_hours"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{two_hours}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("two")) {
                      return prev.filter((e) => e !== "two");
                    }
                    return [...prev, "two"];
                  })
                }
              >
                {isEdit.includes("two") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>3 Hours:</h3>
            {isEdit.includes("three") ? (
              <Input
                value={editValues.three_hours}
                onChange={handleChange}
                name="three_hours"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{three_hours}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("three")) {
                      return prev.filter((e) => e !== "three");
                    }
                    return [...prev, "three"];
                  })
                }
              >
                {isEdit.includes("three") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>4 Hours:</h3>
            {isEdit.includes("four") ? (
              <Input
                value={editValues.four_hours}
                onChange={handleChange}
                name="four_hours"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{four_hours}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("four")) {
                      return prev.filter((e) => e !== "four");
                    }
                    return [...prev, "four"];
                  })
                }
              >
                {isEdit.includes("four") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>Full day:</h3>
            {isEdit.includes("full") ? (
              <Input
                value={editValues.full_day}
                onChange={handleChange}
                name="full_day"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{full_day}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("full")) {
                      return prev.filter((e) => e !== "full");
                    }
                    return [...prev, "full"];
                  })
                }
              >
                {isEdit.includes("full") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>
        </Flex>
        {isEdit.length !== 0 && (
          <Button
            type="primary"
            style={{ maxWidth: 137 }}
            onClick={handleUpdateConfirm}
            loading={loading}
          >
            Confirm Updates
          </Button>
        )}
      </Flex>
    </>
  );
};
export default GroupLessonPrices;
