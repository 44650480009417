import React from "react";
import { OtherActivitiesContentProps } from "../../../types/component-types/otherActivitiesProps";
import { Flex } from "antd";
import BannerOtherActivities from "./BannerOtherActivities/BannerOtherActivities";
import MainActivitiesSection from "./MainActivitiesSection/MainActivitiesSection";
import BottomSliderSection from "../../../components/BottomSliderSection/BottomSliderSection";
import {
  addItemToBottomCarouselSection,
  deleteBottomCarouselItem,
  updateHorseRidingForm,
  updateOtherActivitiesBottomCarouselSection,
  updateQuadBikeForm,
  updateSnowMobileForm,
} from "../../../api/services/otherActivitiesServices";
import FormSection from "./FormSection/FormSection";
import { updateTransfersForm } from "../../../api/services/otherActivitiesServices";
import TransferFormSection from "./FormSection/TransferFormSection";
import { ApiResponse, messageResT } from "src/api";

const OtherActivitiesContent: React.FC<OtherActivitiesContentProps> = ({
  data,
  getData,
}) => {
  if (!data) return <p>Loading...</p>;
  const { banner, mainSection, activities, carouselImages } = data;
  return (
    <Flex vertical gap={84}>
      <BannerOtherActivities bannerData={banner} getData={getData} />
      <MainActivitiesSection data={mainSection} getData={getData} />
      <TransferFormSection
        data={activities.transfersForm}
        updateService={updateTransfersForm}
        getData={getData}
      />
      <FormSection
        header="Snowmobile form details"
        data={activities.snowMobileForm}
        updateService={updateSnowMobileForm}
        getData={getData}
      />
      <FormSection
        header="Horse riding form details"
        data={activities.horseRidingForm}
        updateService={updateHorseRidingForm}
        getData={getData}
      />
      <FormSection
        header="Quad bike form details"
        data={activities.quadBikeForm}
        updateService={updateQuadBikeForm}
        getData={getData}
      />
      <BottomSliderSection
        bottomSliderList={carouselImages.images}
        getData={getData}
        SectionUpdateService={updateOtherActivitiesBottomCarouselSection}
        SectionDeleteService={deleteBottomCarouselItem}
        SectionCreateService={addItemToBottomCarouselSection}
      />
    </Flex>
  );
};

export default OtherActivitiesContent;
