import { useState } from "react";
import { MediaDataT } from "../../../../types/mediaType";
import { Button, Flex, Input } from "antd";
import MediaCardsWrapper from "../../../MediaCardsWrapper/MediaCardsWrapper";
import MediaCard from "../../../MediaCard/MediaCard";
import MediaEditModal from "../../../MediaEditModal/MediaEditModal";
import { MediaCreateModal } from "../../../MediaCreateModal/MediaCreateModal";
import { WhatSetsUsApartProps } from "../../../../types/component-types/homepageProps";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import { messageResT } from "../../../../api/responses";
import {
  addItemToWhatSetsApartSection,
  deleteWhatSetsApartSectionItem,
  updateHomepageWhatSetsApartSection,
} from "../../../../api/services/homePageServices";
import useMessages from "../../../../hooks/useMessages";
import { cloudinaryUpload } from "../../../../lib/cloudinaryUpload";
import { deleteModal } from "../../../../components/DeleteModal";

const WhatsSetsUsApartSection: React.FC<WhatSetsUsApartProps> = ({
  title,
  items,
  getData,
}) => {
  const [editWhatSetsUsApartDetail, setEditWhatSetsUsApartDetail] =
    useState<MediaDataT>();
  const [isEditWhatSetsUsApartModalOpen, setIsEditWhatSetsUsApartModalOpen] =
    useState<boolean>(false);
  const [isEditTitle, setIsEditTitle] = useState<boolean>(false);
  const [titleValue, setTitleValue] = useState<string>(title);
  const [loading, setLoading] = useState<boolean>(false);

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [newItem, setNewItem] = useState<MediaDataT>();

  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const showEditWhatSetsUsApartModal = (id: string) => {
    setIsEditWhatSetsUsApartModalOpen(true);
    const editableWhatSetsUsApart = items.find((item) => item._id === id);
    setEditWhatSetsUsApartDetail(editableWhatSetsUsApart);
  };

  const updateRequestHandler = (
    isEdit: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    try {
      makeRequest(
        updateHomepageWhatSetsApartSection(
          {
            sectionTitle: titleValue,
            itemTitle: editWhatSetsUsApartDetail?.title,
            subtitle: editWhatSetsUsApartDetail?.subtitle,
            image: editWhatSetsUsApartDetail?.image,
          },
          editWhatSetsUsApartDetail?._id
        ),
        (e) => {
          isEdit(false);
          success(e.message);
          getData();
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleOkEditWhatSetsUsApart = () => {
    updateRequestHandler(setIsEditWhatSetsUsApartModalOpen);
  };

  const handleCancelEditWhatSetsUsApart = () => {
    setIsEditWhatSetsUsApartModalOpen(false);
  };

  const handleWhatSetsUsApartFieldChange = async (
    fieldName: string,
    value: string
  ) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }
    setEditWhatSetsUsApartDetail(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as MediaDataT)
    );
    setLoading(false);
  };

  const handleDeleteItem = (id: string) => {
    try {
      makeRequest(deleteWhatSetsApartSectionItem(id), (e) => {
        success(e.message);
        getData();
      });
    } catch (err) {
      error("Something went wrong");
    }
  };

  const onDelete = (id: string) => {
    deleteModal(
      () => handleDeleteItem(id),
      "Are you sure to delete this item?"
    );
  };

  const onTitleUpdateConfirm = () => {
    updateRequestHandler(setIsEditTitle);
  };

  // ------------ Add new item ------------
  const showAddNewItemModal = () => {
    setIsAddModalOpen(true);
  };

  const handleFieldChangeForNewItem = async (fieldName: string, value: any) => {
    let image: any;

    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }

    setNewItem((prev: MediaDataT | undefined) => {
      return {
        ...prev,
        [fieldName]: fieldName === "image" ? image : value,
      } as MediaDataT;
    });
  };

  const handleOkAddNewItem = async () => {
    try {
      if (!newItem) {
        throw new Error("No new item data available");
      }

      makeRequest(addItemToWhatSetsApartSection(newItem), (response) => {
        success(response.message);
        getData();
        setIsAddModalOpen(false);
      });
    } catch (err) {
      error("Failed to add new item");
    }
  };

  const handleCancelAddNewItem = () => {
    setIsAddModalOpen(false);
  };

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <h2>What Sets Us Apart Section</h2>

          <Button style={{ width: 200 }} onClick={showAddNewItemModal}>
            Add new item
          </Button>

          <Flex vertical gap={24}>
            <Flex align="center" gap={24}>
              <Flex
                align="center"
                style={{ maxWidth: 220, width: "100%" }}
                gap={8}
              >
                <p>Title:</p>

                {isEditTitle ? (
                  <Input
                    type="text"
                    value={titleValue}
                    onChange={(e) => setTitleValue(e.target.value)}
                  />
                ) : (
                  <h4>{title}</h4>
                )}
              </Flex>
              <Flex align="center" gap={12}>
                <Button
                  style={{ maxWidth: 120 }}
                  onClick={() => setIsEditTitle(!isEditTitle)}
                >
                  {isEditTitle ? "Cancel" : "Edit"}
                </Button>
                {isEditTitle && (
                  <Button type="primary" onClick={onTitleUpdateConfirm}>
                    Confirm
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>

          <MediaCardsWrapper>
            {items.map((item) => (
              <MediaCard
                key={item._id}
                data={item}
                onEdit={() => showEditWhatSetsUsApartModal(item._id)}
                onDelete={onDelete}
              />
            ))}
          </MediaCardsWrapper>
        </Flex>

        <MediaEditModal
          isMediaEditModalOpen={isEditWhatSetsUsApartModalOpen}
          handleOk={handleOkEditWhatSetsUsApart}
          handleCancel={handleCancelEditWhatSetsUsApart}
          handleFieldChange={handleWhatSetsUsApartFieldChange}
          mediaDetails={editWhatSetsUsApartDetail}
          isLoading={loading}
        />

        <MediaCreateModal
          inputValues={newItem}
          isModalOpen={isAddModalOpen}
          handleOk={handleOkAddNewItem}
          handleCancel={handleCancelAddNewItem}
          handleFieldChange={handleFieldChangeForNewItem}
        />
      </section>
    </>
  );
};

export default WhatsSetsUsApartSection;
