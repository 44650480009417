import { Button, Flex, Input, Tooltip } from "antd";
import React, { useState } from "react";
import {
  LocationInfoT,
  messageResT,
  updateIndividualLocationInfo,
} from "src/api";
import { useApiRequest } from "src/hooks/useApiRequests";
import useMessages from "src/hooks/useMessages";

const LocationInfoItem: React.FC<
  {
    onEdit: (id: string) => void;
    handleUpdate: () => void;
    handleChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    editValue: LocationInfoT;
  } & LocationInfoT
> = ({ _id, title, link, handleUpdate, editValue, handleChange, onEdit }) => {
  return (
    <Flex align="center" gap={12}>
      {editValue._id === _id ? (
        <Flex vertical gap={8}>
          <Input value={editValue.title} name="title" onChange={handleChange} />
          <textarea
            value={editValue.link}
            style={{ padding: "10px", borderRadius: 8 }}
            name="link"
            onChange={handleChange}
          />
        </Flex>
      ) : (
        <Tooltip title={link}>
          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
            {title}
          </span>
        </Tooltip>
      )}

      <Flex align="center" gap={8}>
        <Button onClick={() => onEdit(_id)}>
          {editValue._id === _id ? "Cancel" : "Edit"}
        </Button>

        {editValue._id === _id && (
          <Button type="primary" onClick={handleUpdate}>
            Confirm
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default LocationInfoItem;
