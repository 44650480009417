import { Button, Flex } from "antd";
import React from "react";
import { LessonT } from "src/api";
import classes from "./SkiLessonsMediaCard.module.css";

type LessonMediaCardProps = {
  data: LessonT;
  onEdit: (id: string) => void;
};

const SkiLessonsMediaCard: React.FC<LessonMediaCardProps> = ({
  data,
  onEdit,
}) => {
  const { _id, title, subtitle, locationTitle } = data;

  return (
    <Flex vertical gap={24} className={classes.mediaCard}>
      {title && (
        <Flex vertical>
          <h4 className={classes.textIndicator}>Title: </h4>
          <h4>{title}</h4>
        </Flex>
      )}

      {subtitle && (
        <Flex vertical>
          <h4 className={classes.textIndicator}>Subtitle:</h4>
          <h4>{subtitle}</h4>
        </Flex>
      )}

      {locationTitle && (
        <Flex vertical>
          <h4 className={classes.textIndicator}>Location Title: </h4>
          <h4>{locationTitle}</h4>
        </Flex>
      )}

      <Flex justify="flex-end" style={{ width: "100%" }}>
        <Button onClick={() => onEdit(_id)}>Edit</Button>
      </Flex>
    </Flex>
  );
};

export default SkiLessonsMediaCard;
