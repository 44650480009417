import { useRef, useState } from "react";
import { Button, Flex, Modal } from "antd";
import MediaCardsWrapper from "../MediaCardsWrapper/MediaCardsWrapper";
import classes from "./bottomSlider.module.css";
import { BottomSliderProps } from "../../types/component-types/homepageProps";
import { useApiRequest } from "../../hooks/useApiRequests";
import useMessages from "../../hooks/useMessages";
import { messageResT } from "../../api/responses";
import { MultipleImagesT } from "../../types/common-types";
import { cloudinaryUpload } from "../../lib/cloudinaryUpload";
import { deleteModal } from "../../components/DeleteModal";
import ImageCard from "../ImageCard/ImageCard";
import BannerResolutionWarning from "../BannerResolutionWarning/BannerResolutionWarning";

const BottomSliderSection: React.FC<BottomSliderProps> = ({
  title,
  bottomSliderList,
  getData,
  SectionUpdateService,
  SectionDeleteService,
  SectionCreateService,
  bannerWarning = false,
}) => {
  const [prevImage, setPrevImage] = useState<string | null>(null);
  const [editBottomSliderDetail, setEditBottomSliderDetail] =
    useState<MultipleImagesT | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [newImage, setNewImage] = useState<Omit<MultipleImagesT, "_id"> | null>(
    null
  );
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { error, success, contextHolder } = useMessages();
  const [, makeRequest] = useApiRequest<messageResT>();

  const showEditModal = (id: string) => {
    const editableItem = bottomSliderList.find((item) => item._id === id);
    if (editableItem) {
      setEditBottomSliderDetail(editableItem);
      setPrevImage(editableItem.url);
      setIsEditModalOpen(true);
    }
  };

  const handleImageChange = async (file: File) => {
    try {
      setLoading(true);
      const uploadedImage = await cloudinaryUpload(file);
      if (uploadedImage) {
        if (isEditModalOpen) {
          setEditBottomSliderDetail({
            ...editBottomSliderDetail!,
            ...uploadedImage,
          });
        } else {
          setNewImage(uploadedImage);
        }
      }
      setPrevImage(URL.createObjectURL(file));
      setLoading(false);
    } catch (err) {
      error("Image upload failed");
    }
  };

  const handleEditSave = () => {
    try {
      setLoading(true);
      if (editBottomSliderDetail) {
        makeRequest(
          SectionUpdateService(
            { image: editBottomSliderDetail },
            editBottomSliderDetail._id
          ),
          (response) => {
            success(response.message);
            getData();
            setIsEditModalOpen(false);
            setPrevImage(null);
            if (inputRef.current) inputRef.current.value = "";
          }
        );
      }
      setLoading(false);
    } catch (err) {
      error("Something went wrong");
      if (inputRef.current) inputRef.current.value = "";
    }
  };

  const handleDeleteItem = (id: string) => {
    try {
      setLoading(true);
      makeRequest(SectionDeleteService(id), (e) => {
        success(e.message);
        getData();
        setLoading(false);
      });
    } catch (err) {
      error("Something went wrong");
    }
  };

  const onDelete = (id: string) => {
    deleteModal(
      () => handleDeleteItem(id),
      "Are you sure to delete this item?"
    );
  };

  const handleAddNewItem = () => {
    try {
      setLoading(true);
      if (newImage) {
        makeRequest(SectionCreateService({ images: newImage }), (response) => {
          if (response && response.message) {
            success(response.message);
          } else {
            success("Image added successfully");
          }
          getData();
          setPrevImage(null);
          setIsAddModalOpen(false);
          setLoading(false);
          if (inputRef.current) inputRef.current.value = "";
        });
      }
    } catch (err) {
      error("Something went wrong");
    }
  };

  return (
    <>
      {contextHolder}

      <section>
        <Flex vertical gap={12}>
          <h2>{title ? title : "Bottom Slider"}</h2>

          {bannerWarning && <BannerResolutionWarning />}

          <Button
            style={{ width: 200 }}
            onClick={() => setIsAddModalOpen(true)}
          >
            Add new item
          </Button>

          <MediaCardsWrapper>
            {bottomSliderList.map((item) => (
              <ImageCard
                key={item._id}
                data={item}
                onEdit={() => showEditModal(item._id)}
                onDelete={onDelete}
              />
            ))}
          </MediaCardsWrapper>
        </Flex>

        <Modal
          title="Edit Item"
          open={isEditModalOpen}
          onOk={handleEditSave}
          onCancel={() => setIsEditModalOpen(false)}
          className={classes.editModal}
          okButtonProps={{ loading }}
          centered
        >
          {prevImage && (
            <img className={classes.prevImg} src={prevImage} alt="Preview" />
          )}
          <input
            type="file"
            onChange={(e) =>
              e.target.files && handleImageChange(e.target.files[0])
            }
            ref={inputRef}
          />
        </Modal>

        <Modal
          title="Add New Item"
          open={isAddModalOpen}
          onOk={handleAddNewItem}
          onCancel={() => setIsAddModalOpen(false)}
          className={classes.editModal}
          okButtonProps={{ loading }}
          centered
        >
          {prevImage && (
            <img className={classes.prevImg} src={prevImage} alt="Preview" />
          )}
          <input
            type="file"
            onChange={(e) =>
              e.target.files && handleImageChange(e.target.files[0])
            }
            ref={inputRef}
          />
        </Modal>
      </section>
    </>
  );
};

export default BottomSliderSection;
