import { Button, Flex, Input } from "antd";
import React, { useState } from "react";
import { ActivitiesReqT, messageResT } from "src/api";
import {
  updatePriceManagementHorseRiding,
  updatePriceManagementSnowmobile,
} from "src/api/services/priceManagementServices";
import { useApiRequest } from "src/hooks/useApiRequests";
import useMessages from "src/hooks/useMessages";
import { ActivitiesComponentPriceUpdateProps } from "src/types";

const ActivitiesPrices: React.FC<ActivitiesComponentPriceUpdateProps> = ({
  title,
  getData,
  _id,
  hour,
  minutes_15,
  minutes_30,
  isSnowMobile,
}) => {
  const [editValues, setEditValues] = useState<ActivitiesReqT>({
    _id,
    hour,
    minutes_15,
    minutes_30,
  });
  const [isEdit, setIsEdit] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateConfirm = () => {
    try {
      setLoading(true);
      makeRequest(
        (isSnowMobile
          ? updatePriceManagementSnowmobile
          : updatePriceManagementHorseRiding)(
          { ...editValues },
          editValues._id
        ),
        (e) => {
          success(e.message);
          getData();
          setIsEdit([]);
          setLoading(false);
        }
      );
    } catch (err) {
      error("Something went wrong");
    } finally {
      setLoading(false);
      setIsEdit([]);
    }
  };

  return (
    <>
      {contextHolder}
      <Flex gap={24} vertical align="flex-start">
        <Flex gap={24} vertical>
          <h2>{title}</h2>

          <Flex gap={12} align="center">
            <h3>1 Hour:</h3>
            {isEdit.includes("hour") ? (
              <Input
                value={editValues.hour}
                onChange={handleChange}
                name="hour"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{hour}</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("hour")) {
                      return prev.filter((e) => e !== "hour");
                    }
                    return [...prev, "hour"];
                  })
                }
              >
                {isEdit.includes("hour") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>15 minutes:</h3>
            {isEdit.includes("minutes_15") ? (
              <Input
                value={editValues.minutes_15}
                onChange={handleChange}
                name="minutes_15"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{minutes_15}</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("minutes_15")) {
                      return prev.filter((e) => e !== "minutes_15");
                    }
                    return [...prev, "minutes_15"];
                  })
                }
              >
                {isEdit.includes("minutes_15") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>15 minutes:</h3>
            {isEdit.includes("minutes_30") ? (
              <Input
                value={editValues.minutes_30}
                onChange={handleChange}
                name="minutes_30"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{minutes_30}</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("minutes_30")) {
                      return prev.filter((e) => e !== "minutes_30");
                    }
                    return [...prev, "minutes_30"];
                  })
                }
              >
                {isEdit.includes("minutes_30") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>
        </Flex>

        {isEdit.length !== 0 && (
          <Button
            type="primary"
            style={{ maxWidth: 137 }}
            onClick={handleUpdateConfirm}
            loading={loading}
          >
            Confirm Updates
          </Button>
        )}
      </Flex>
    </>
  );
};

export default ActivitiesPrices;
