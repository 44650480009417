import { Flex } from "antd";
import React from "react";
import classes from "./SkiLessonsMediaCard.module.css";
import { LocationInfoT } from "src/api";
import LocationInfoItem from "./components/LocationInfoItem";
import { LocationInfoMediaCardProps } from "src/types";

const LocationInfoMediaCard: React.FC<LocationInfoMediaCardProps> = ({
  data,
  handleLocationUpdate,
  handleChange,
  onEdit,
  editValue,
}) => {
  return (
    <div className={classes.mediaCard}>
      <div className={classes.content}>
        {data.map((e) => (
          <LocationInfoItem
            onEdit={onEdit}
            handleChange={handleChange}
            editValue={
              editValue
                ? editValue
                : {
                    _id: "",
                    title: "",
                    link: "",
                  }
            }
            handleUpdate={handleLocationUpdate}
            {...e}
            key={e._id}
          />
        ))}
      </div>
    </div>
  );
};

export default LocationInfoMediaCard;
