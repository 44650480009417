import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./views/Login";
import DashboardPage from "./views/Dashboard";
import SkiSchoolPage from "./views/SkiSchool";
import ParaglidingPage from "./views/Paragliding";
import OtherActivitiesPage from "./views/OtherActivities";
import ContactPage from "./views/Contact";
import PrivateLayout from "./components/PrivateLayout";
import ActivitiesSwitcherPage from "./views/ActivitiesSwitcher";
import ContentManagementPage from "./views/ContentManagement/ContentManagement";
import SEOOptimizationPage from "./views/SEOOptimization/SEOOptimization";
import PriceDateManagement from "./views/price-date-management";
import Coupon from "./views/coupon/Coupon";
import SubscribedPage from "./views/subscribed-page/SubscribedPage";

const RoutesComponent: React.FC = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route element={<PrivateLayout />}>
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/skischool" element={<SkiSchoolPage />} />
      <Route path="/paragliding" element={<ParaglidingPage />} />
      <Route path="/other-activities" element={<OtherActivitiesPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route
        path="/activities-management"
        element={<ActivitiesSwitcherPage />}
      />
      <Route path="/content-management" element={<ContentManagementPage />} />
      <Route
        path="/seo-optimization-management"
        element={<SEOOptimizationPage />}
      />
      <Route path="/price-date-management" element={<PriceDateManagement />} />
      <Route path="/coupon" element={<Coupon />} />{" "}
      <Route path="/subscribed-emails" element={<SubscribedPage />} />
    </Route>

    {/* Other routes */}
  </Routes>
);

export default RoutesComponent;
