import { jwtDecode } from "jwt-decode";

export const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;
  
    const decoded = jwtDecode(token);
  
    if (!decoded.exp) {
      localStorage.removeItem("token");
      return false;
    }

  
    const isTokenValid = decoded.exp * 1000 > Date.now();
  
  
    if (!isTokenValid) {
      localStorage.removeItem("token");
    }
  
    return isTokenValid;
  };