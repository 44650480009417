import { Flex, Input, Modal } from "antd";
import { useRef, useState } from "react";
import classes from "./MediaCreateModal.module.css";
import { MediaDataT } from "src/types";

type MediaEditModalProps = {
  isModalOpen: boolean;
  inputValues: MediaDataT | undefined;
  handleOk: () => void;
  handleCancel: () => void;
  handleFieldChange: (dataIndex: string, value: any) => void;
  customTitle?: string;
};

export const MediaCreateModal: React.FC<MediaEditModalProps> = ({
  isModalOpen,
  inputValues,
  handleOk,
  handleCancel,
  handleFieldChange,
  customTitle,
}) => {
  const [prevImage, setPrevImage] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPrevImage(URL.createObjectURL(e.target.files[0]));
      handleFieldChange("image", e.target.files[0]);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    handleFieldChange(name, value);
  };

  const onOk = () => {
    setPrevImage("");
    handleOk();
    if (inputRef.current) inputRef.current.value = "";
  };

  return (
    <Modal
      title={customTitle ? customTitle : "Create Item"}
      open={isModalOpen}
      onOk={onOk}
      onCancel={handleCancel}
      className={classes.editModal}
      centered
    >
      <Flex vertical gap={12}>
        <Flex vertical gap={24}>
          <input type="file" ref={inputRef} onChange={handleImageChange} />
          {prevImage && <img src={prevImage} alt="img" />}
        </Flex>

        <div className={classes.content}>
          <p>Title: </p>
          <Input
            type="text"
            name="title"
            value={inputValues?.title}
            onChange={handleInputChange}
          />
        </div>

        <div className={classes.content}>
          <p>Subtitle: </p>
          <Input
            type="text"
            name="subtitle"
            value={inputValues?.subtitle}
            onChange={handleInputChange}
          />
        </div>
      </Flex>
    </Modal>
  );
};
