import { PriceDataManagementContentProps } from "../../types";
import { Flex } from "antd";
import React from "react";
import IndividualSkiLessonPrices from "./IndividualSki/IndividualSkiLessonPrices";
import GroupLessonPrices from "./group-lesson-prices/GroupLessonPrices";
import ParaglidingPriceUpdate from "./paragliding/ParaglidingPriceUpdate";
import QuadBikeUpdatePrice from "./quad-bike-prices/QuadBikeUpdatePrice";
import SkiDates from "./ski-dates/SkiDates";
import DatesManagementController from "./dates-management-controller/DatesManagementController";
import {
  updateDatesManagementOther,
  updateDatesManagementParagliding,
} from "src/api/services";
import TransfersPrices from "./transfers-prices/TransfersPrices";
import ActivitiesPrices from "./activities-prices/ActivitiesPrices";

const PriceDateManagementContent: React.FC<PriceDataManagementContentProps> = ({
  pricesData,
  datesData,
  getData,
}) => {
  const {
    individualSkiLesson,
    individualSnowboard,
    groupSkiLesson,
    groupSnowboard,
    paragliding,
    quadBike,
    transfer,
    snowmobile,
    horseRiding,
  } = pricesData;

  const { skiSchoolDates, paraglidingDates, otherActivitiesDates } = datesData;

  return (
    <section>
      <Flex vertical gap={80}>
        <h1 style={{ fontSize: 35 }}>Price Management</h1>

        <Flex vertical gap={80}>
          <IndividualSkiLessonPrices
            title="Ski Lesson"
            {...individualSkiLesson}
            getData={getData}
            isSki
          />
          <IndividualSkiLessonPrices
            title="Snowboard Lesson"
            {...individualSnowboard}
            getData={getData}
          />
          <GroupLessonPrices
            title="Ski Lesson"
            {...groupSkiLesson}
            getData={getData}
            isSki
          />
          <GroupLessonPrices
            title="Snowboard Lesson"
            {...groupSnowboard}
            getData={getData}
          />

          <ParaglidingPriceUpdate {...paragliding} getData={getData} />

          <ActivitiesPrices
            {...horseRiding}
            getData={getData}
            title="Horse Riding"
          />

          <TransfersPrices {...transfer} getData={getData} />

          <ActivitiesPrices
            {...snowmobile}
            isSnowMobile
            getData={getData}
            title="Snow Mobile"
          />

          <QuadBikeUpdatePrice {...quadBike} getData={getData} />
        </Flex>

        <h1 style={{ fontSize: 35 }}>Date Management</h1>
        {skiSchoolDates
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((e) => (
            <SkiDates {...e} getData={getData} key={e._id} />
          ))}
        <DatesManagementController
          updateService={updateDatesManagementParagliding}
          {...paraglidingDates}
          name="Paragliding"
          getData={getData}
        />
        {otherActivitiesDates.map((e) => (
          <DatesManagementController
            {...e}
            name={e.name.toUpperCase()}
            getData={getData}
            updateService={updateDatesManagementOther}
          />
        ))}
      </Flex>
    </section>
  );
};

export default PriceDateManagementContent;
