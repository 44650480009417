import { Flex } from "antd";
import BannerSkiSchool from "./BannerSkiSchool/BannerSkiSchool";
import { SkiSchoolContentProps } from "../../../types/component-types/skiSchoolPageProps";
import AboutSkiSchool from "./AboutSkiSchool/AboutSkiSchool";
import IndividualLesson from "./IndividualLesson/IndividualLesson";
import GroupLesson from "./GroupLesson/GroupLesson";
import BenefitsOfSkiing from "./BenefitsOfSkiing/BenefitsOfSkiing";
import RentalShop from "./RentalShop/RentalShop";
import Repair from "./Repair/Repair";
import OurTeam from "./OurTeam/OurTeam";

const SkiSchoolContent: React.FC<SkiSchoolContentProps> = ({
  data,
  getData,
}) => {
  if (!data) return <p>Loading...</p>;

  const {
    banner,
    aboutSection,
    lessons,
    benefitsSection,
    rentalShopSection,
    repairSection,
    teamSection,
  } = data;

  return (
    <Flex vertical gap={84}>
      <BannerSkiSchool bannerData={banner} getData={getData} />
      <AboutSkiSchool aboutSkiSchoolData={aboutSection} getData={getData} />
      <IndividualLesson
        individualLessonData={lessons.individualLesson}
        getData={getData}
      />
      <GroupLesson groupLessonData={lessons.groupLesson} getData={getData} />
      <BenefitsOfSkiing
        benefitsSectionData={benefitsSection}
        getData={getData}
      />
      <RentalShop rentalShopData={rentalShopSection} getData={getData} />
      <Repair repairData={repairSection} getData={getData} />
      <OurTeam data={teamSection} getData={getData} />
    </Flex>
  );
};

export default SkiSchoolContent;
