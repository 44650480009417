import { useState, useEffect } from "react";
import { Flex, Modal, Skeleton } from "antd";
import {
  PiHorseDuotone,
  PiSnowflakeDuotone,
  PiTrainRegionalDuotone,
  PiPersonSimpleBikeDuotone,
  PiParachuteDuotone,
} from "react-icons/pi";
import { LiaSkiingSolid } from "react-icons/lia";
import { SwitcherComponent } from "./SwitcherComponent";
import classes from "./activitiesSwitcher.module.css";

type StatusesT = {
  _id: string;
  horseRiding: string;
  quadBike: string;
  snowMobile: string;
  transfersTours: string;
  paragliding: string;
  snowSchool: string;
};

export const ActivitiesSwitcher = () => {
  const [otherActivitiesStatus, setOtherActivitiesStatus] = useState({
    horseRiding: "",
    quadBike: "",
    snowMobile: "",
    transfersTours: "",
    paragliding: "",
    snowSchool: "",
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/activitiesmanagement`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data: StatusesT[]) => {
        setOtherActivitiesStatus((prev) => ({
          ...prev,
          horseRiding: data[data.length - 1].horseRiding,
          quadBike: data[data.length - 1].quadBike,
          snowMobile: data[data.length - 1].snowMobile,
          transfersTours: data[data.length - 1].transfersTours,
          paragliding: data[data.length - 1].paragliding,
          snowSchool: data[data.length - 1].snowSchool,
        }));
        setLoading(false);
      })
      .catch((error: Error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const skeletonButtons = Array.from({ length: 6 }, (_, index) => (
    <Skeleton.Button
      key={index}
      active
      size="large"
      block
      className={classes.skeletonCard}
    />
  ));

  if (loading) {
    return (
      <Flex vertical gap={36}>
        <Skeleton.Input active />
        <Flex vertical gap={16}>
          {skeletonButtons}
        </Flex>
      </Flex>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const onChange = async (checked: boolean, fieldName: string) => {
    const updatedStatus = {
      ...otherActivitiesStatus,
      [fieldName]: checked ? "true" : "false",
    };

    setOtherActivitiesStatus(updatedStatus);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/activitiesmanagement`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedStatus),
        }
      );

      if (response.ok) {
        fetchData();
        successMessage();
      } else {
        console.error("Request failed");
        errorMessage();
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const successMessage = () => {
    Modal.success({
      content: "Activity status changed successfully!",
    });
  };

  const errorMessage = () => {
    Modal.error({
      content: "Something went wrong!",
    });
  };

  return (
    <div className={classes.mainDiv}>
      <h2>Activities Switching Management</h2>
      <Flex vertical gap={16}>
        <SwitcherComponent
          icon={<LiaSkiingSolid />}
          title="Ski School"
          defaultChecked={
            otherActivitiesStatus.snowSchool &&
            otherActivitiesStatus.snowSchool === "true"
              ? true
              : false
          }
          onChange={(checked) => onChange(checked, "snowSchool")}
        />

        <SwitcherComponent
          icon={<PiParachuteDuotone />}
          title="Paragliding"
          defaultChecked={
            otherActivitiesStatus.paragliding &&
            otherActivitiesStatus.paragliding === "true"
              ? true
              : false
          }
          onChange={(checked) => onChange(checked, "paragliding")}
        />

        <SwitcherComponent
          icon={<PiHorseDuotone />}
          title={"Horse Riding"}
          defaultChecked={
            otherActivitiesStatus.horseRiding &&
            otherActivitiesStatus.horseRiding === "true"
              ? true
              : false
          }
          onChange={(checked) => onChange(checked, "horseRiding")}
        />

        <SwitcherComponent
          icon={<PiSnowflakeDuotone />}
          title="Snow Mobile"
          defaultChecked={
            otherActivitiesStatus.snowMobile &&
            otherActivitiesStatus.snowMobile === "true"
              ? true
              : false
          }
          onChange={(checked) => onChange(checked, "snowMobile")}
        />

        <SwitcherComponent
          icon={<PiTrainRegionalDuotone />}
          title="Transfers and Tours"
          defaultChecked={
            otherActivitiesStatus.transfersTours &&
            otherActivitiesStatus.transfersTours === "true"
              ? true
              : false
          }
          onChange={(checked) => onChange(checked, "transfersTours")}
        />

        <SwitcherComponent
          icon={<PiPersonSimpleBikeDuotone />}
          title="Quad Bike"
          defaultChecked={
            otherActivitiesStatus.quadBike &&
            otherActivitiesStatus.quadBike === "true"
              ? true
              : false
          }
          onChange={(checked) => onChange(checked, "quadBike")}
        />
      </Flex>
    </div>
  );
};
