import { Button, Flex, Input } from "antd";
import React, { useRef, useState } from "react";
import { InfoItemComponentProps } from "src/types";

const InfoItem: React.FC<InfoItemComponentProps> = ({
  _id,
  description,
  image,
  itemEditValue,
  loading,
  onEdit,
  handleFieldChange,
  handleUpdate,
}) => {
  const [prevImage, setPrevImage] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setPrevImage(URL.createObjectURL(selectedFile));
      handleFieldChange("image", selectedFile);
    }
  };

  const handleConfirm = () => {
    handleUpdate();
    setPrevImage(null);
    if (inputRef.current) inputRef.current.value = "";
  };

  const handleCancel = (id: string) => {
    onEdit(id);
    setPrevImage(null);
    if (inputRef.current) inputRef.current.value = "";
  };

  return (
    <Flex align="center" gap={12}>
      {itemEditValue._id === _id ? (
        <Flex vertical gap={8}>
          {prevImage && (
            <img
              src={prevImage}
              style={{ width: 24, height: 24 }}
              alt="prev-image"
            />
          )}
          <input type="file" onChange={handleImageChange} />

          <Input
            value={itemEditValue.description}
            onChange={(e) => handleFieldChange("description", e.target.value)}
          />
        </Flex>
      ) : (
        <Flex align="center" gap={8}>
          <img src={image.url} alt="logo" style={{ width: 24, height: 24 }} />

          <span>{description}</span>
        </Flex>
      )}

      <Flex align="center" gap={8}>
        <Button
          onClick={() =>
            itemEditValue._id === _id ? handleCancel(_id) : onEdit(_id)
          }
          loading={loading}
        >
          {itemEditValue._id === _id ? "Cancel" : "Edit"}
        </Button>

        {itemEditValue._id === _id && (
          <Button type="primary" onClick={handleConfirm} loading={loading}>
            Confirm
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default InfoItem;
