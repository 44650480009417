import { useApiRequest } from "../../../../hooks/useApiRequests";
import useMessages from "../../../../hooks/useMessages";
import { FormSectionProps } from "../../../../types/component-types";
import { Flex } from "antd";
import React, { useState } from "react";
import { ItemT, LocationInfoT, messageResT } from "../../../../api/responses";
import {
  updateParaglidingLocation,
  updateParaglidingPageFormSection,
} from "../../../../api/services";
import InfoItemsMediaCard from "src/components/SkiLessonsMediaCard/InfoItemsMediaCard";
import { cloudinaryUpload } from "src/lib/cloudinaryUpload";
import LocationInfoMediaCard from "src/components/SkiLessonsMediaCard/LocationInfoMediaCard";
import MediaCard from "src/components/MediaCard/MediaCard";
import MediaEditModal from "src/components/MediaEditModal/MediaEditModal";
import { MediaDataT } from "src/types";

type MediaEditT = {
  locationTitle: string;
  warning: string;
  warningTitle: string;
} & MediaDataT;

const FormSection: React.FC<FormSectionProps> = ({ data, getData }) => {
  const {
    _id,
    title,
    subtitle,
    items,
    locationTitle,
    locationInfo,
    warningTitle,
    warning,
  } = data;
  const [editLocationInfo, setEditLocationInfo] = useState<LocationInfoT>();
  const [mediaDetails, setMediaDetails] = useState<MediaEditT>();

  const [itemEditValue, setItemEditValue] = useState<ItemT>();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleItemEdit = (id: string) => {
    const filteredData = items.find((item) => item._id === id);

    setItemEditValue((prev) => {
      if (prev === filteredData) return undefined;

      return filteredData;
    });
  };

  const handleOnMediaEdit = () => {
    setMediaDetails(
      (prev) =>
        ({
          ...prev,
          title,
          subtitle,
          warningTitle,
          warning,
          locationTitle,
        } as MediaEditT)
    );

    setOpen(true);
  };

  const handleOnMediaEditCancel = () => {
    setMediaDetails(undefined);

    setOpen(false);
  };

  const onTitleEditConfirm = () => {
    try {
      if (mediaDetails) {
        setLoading(true);
        makeRequest(updateParaglidingPageFormSection(mediaDetails), (e) => {
          success(e.message);
          getData();
          setLoading(false);
          setOpen(false);
          setMediaDetails(undefined);
        });
      }
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleOnEdit = (id: string) => {
    const filteredValue = locationInfo.find((e) => e._id === id);
    setEditLocationInfo((prev) => {
      if (prev === filteredValue) return undefined;

      return filteredValue;
    });
  };

  const handleLocationUpdate = () => {
    try {
      if (editLocationInfo) {
        setLoading(true);
        makeRequest(
          updateParaglidingLocation(editLocationInfo, editLocationInfo._id),
          (e) => {
            success(e.message);
            setEditLocationInfo(undefined);
            getData();
          }
        );
        setLoading(false);
      }
    } catch (err) {
      error("Something went wrong");
    }
  };

  const onItemEditConfirm = () => {
    try {
      setLoading(true);
      makeRequest(
        updateParaglidingPageFormSection(
          {
            description: itemEditValue?.description,
            image: itemEditValue?.image,
          },
          itemEditValue?._id
        ),
        (e) => {
          success(e.message);
          setItemEditValue(undefined);
          getData();
          setLoading(false);
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleItemFieldChange = async (fieldName: string, value: any) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }
    setItemEditValue(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as ItemT)
    );
    setLoading(false);
  };

  const handleMediaFieldChange = (fieldName: string, value: string) => {
    setMediaDetails(
      (prev) =>
        ({
          ...prev,
          [fieldName]: value,
        } as MediaEditT)
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setEditLocationInfo(
      (prev) =>
        ({
          ...prev,
          [name]: value,
        } as LocationInfoT)
    );
  };

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12} style={{ width: "100%" }}>
          <h2>Form Section</h2>
          <Flex vertical gap={24} style={{ width: "100%" }}>
            <MediaCard
              data={{
                _id: _id,
                title: title,
                subtitle: subtitle,
                locationTitle: locationTitle,
                warning: warning,
                warningTitle: warningTitle,
              }}
              onEdit={() => handleOnMediaEdit()}
            />

            <MediaEditModal
              isMediaEditModalOpen={open}
              handleOk={onTitleEditConfirm}
              handleCancel={handleOnMediaEditCancel}
              handleFieldChange={handleMediaFieldChange}
              mediaDetails={mediaDetails}
              isLoading={loading}
              containsImage={false}
            />

            <LocationInfoMediaCard
              data={locationInfo}
              editValue={editLocationInfo}
              handleChange={handleChange}
              handleLocationUpdate={handleLocationUpdate}
              onEdit={handleOnEdit}
            />

            <Flex vertical gap={12}>
              <h2>Items: </h2>
              <InfoItemsMediaCard
                data={items}
                itemEditValue={itemEditValue}
                loading={loading}
                onEdit={handleItemEdit}
                handleFieldChange={handleItemFieldChange}
                handleUpdate={onItemEditConfirm}
              />
            </Flex>
          </Flex>
        </Flex>
      </section>
    </>
  );
};

export default FormSection;
