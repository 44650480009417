import { Flex } from "antd";
import { ContactPageContentProps } from "../../../types";
import React, { useState } from "react";
import BottomSliderSection from "../../../components/BottomSliderSection/BottomSliderSection";
import {
  createContactPageImageBanner,
  createContactPageCarouselImage,
  deleteContactPageImageBanner,
  deleteContactPageCarouselImage,
  updateContactPageImageBanner,
  updateContactPageCarouselImage,
  updateContactPageBanner,
} from "../../../api/services";
import FAQSection from "./FAQSection/FAQSection";
import MediaCard from "src/components/MediaCard/MediaCard";
import MediaEditModal from "src/components/MediaEditModal/MediaEditModal";
import { ContactBannerTextsT } from "src/api/responses/contactPageResponses";
import { useApiRequest } from "src/hooks/useApiRequests";
import { messageResT } from "src/api";
import useMessages from "src/hooks/useMessages";

const ContactPageContent: React.FC<ContactPageContentProps> = ({
  data,
  getData,
}) => {
  const { banner, faqTitles, faqQuestions, faq, carouselImages } = data;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [mediaDetails, setMediaDetails] = useState<ContactBannerTextsT>();
  const { success, error, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleOpen = () => {
    const { images, ...rest } = banner;

    setMediaDetails((prev) => ({
      ...prev,
      ...rest,
    }));
    setIsEdit(true);
  };

  const handleUpdate = () => {
    try {
      if (mediaDetails) {
        makeRequest(updateContactPageBanner(mediaDetails), (e) => {
          success(e.message);
          setIsEdit(false);
          getData();
        });
      }
    } catch (err) {
      console.log(err);
      error("Something went wrong");
    }
  };

  const handleCancel = () => {
    setMediaDetails(undefined);
    setIsEdit(false);
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    setMediaDetails(
      (prev) =>
        ({
          ...prev,
          [fieldName]: value,
        } as ContactBannerTextsT)
    );
  };

  return (
    <>
      {contextHolder}
      <Flex vertical gap={80}>
        <MediaCard
          data={{
            _id: banner._id,
            title: banner.title,
            email: banner.email,
            phoneNumber: banner.phoneNumber,
            location: banner.location,
            locationLink: banner.locationLink,
          }}
          onEdit={() => handleOpen()}
        />

        <MediaEditModal
          isMediaEditModalOpen={isEdit}
          handleOk={handleUpdate}
          handleCancel={handleCancel}
          handleFieldChange={handleFieldChange}
          containsImage={false}
          mediaDetails={
            mediaDetails
              ? {
                  title: mediaDetails.title,
                  email: mediaDetails.email,
                  phoneNumber: mediaDetails.phoneNumber,
                  location: mediaDetails.location,
                  locationLink: mediaDetails.locationLink,
                }
              : undefined
          }
          isLoading={false}
        />

        <BottomSliderSection
          title="Banner"
          bottomSliderList={banner.images}
          SectionCreateService={createContactPageImageBanner}
          SectionDeleteService={deleteContactPageImageBanner}
          SectionUpdateService={updateContactPageImageBanner}
          getData={getData}
          bannerWarning
        />
        <FAQSection
          faqData={faq}
          faqQuestions={faqQuestions}
          faqTitles={faqTitles}
          getData={getData}
        />
        <BottomSliderSection
          bottomSliderList={carouselImages.images}
          SectionCreateService={createContactPageCarouselImage}
          SectionDeleteService={deleteContactPageCarouselImage}
          SectionUpdateService={updateContactPageCarouselImage}
          getData={getData}
        />
      </Flex>
    </>
  );
};

export default ContactPageContent;
