import { getAxiosClient } from "../client";
import {
  addItemToBannerSkiSchoolEndpoint,
  deleteBannerSkiSchoolItemEndpoint,
  getSkiSchoolPageEndpoint,
  putIndividualLocationInfo,
  updateAboutSectionEndpoint,
  updateBannerSkiSchoolEndpoint,
  updateBenefitsSectionEndpoint,
  updateGroupLessonEndpoint,
  updateIndividualLessonEndpoint,
  updateRentalShopSectionEndpoint,
  updateRepairSectionEndpoint,
  updateTeamSectionEndpoint,
} from "../endpoints/skiSchool";
import { makeApiRequest } from "../requests";
import { ApiResponse, messageResT } from "../responses";
import { GetSkiSchoolPageResT, LocationInfoT } from "../responses/skiSchoolPageResponses";
import { CloudinaryImageT } from "../../types";

// ========== GET ========== //
export const getSkiSchoolPageData = (): Promise<
  ApiResponse<GetSkiSchoolPageResT>
> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getSkiSchoolPageEndpoint()
  );
  const result = makeApiRequest<GetSkiSchoolPageResT>(httpRequest);

  return result;
};

// ========== POST ========== //
export const addItemToBannerSkiSchool = (data: {
  image: {
    public_id: string;
    url: string;
  };
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    addItemToBannerSkiSchoolEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== PUT =========== //

export const updateBannerSkiSchoolSection = (
  data: {
    title?: string;
    subtitle?: string;
    image?: {
      public_id: string;
      url: string;
    };
  },
  imgId?: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateBannerSkiSchoolEndpoint(imgId),
    data
  );

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateAboutSectionSkiSchool = (
  id: string,
  data: {
    title?: string;
    subtitle?: string;
    image?: {
      public_id: string;
      url: string;
    };
  }
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateAboutSectionEndpoint(id),
    data
  );

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateIndividualLesson = (data: {
  title?: string;
  subtitle?: string;
  locationTitle?: string;
  description?: string;
  image?: {
    public_id: string;
    url: string;
  };
}, itemId?: string): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateIndividualLessonEndpoint(itemId),
    data
  );

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateIndividualLocationInfo = (data: Omit<LocationInfoT,"_id">, id: string): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(putIndividualLocationInfo(id), data);

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
}

export const updateGroupLesson = (data: {
  title?: string;
  subtitle?: string;
  locationTitle?: string;
  description?: string;
  image?: {
    public_id: string;
    url: string;
  };
},itemId?: string): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateGroupLessonEndpoint(itemId),
    data
  );

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateBenefitsSection = (
  data: {
    sectionTitle?: string;
    subtitle?: string;
    image?: {
      public_id: string;
      url: string;
    };
  },
  itemId?: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateBenefitsSectionEndpoint(itemId),
    data
  );

  return makeApiRequest<messageResT>(httpRequest);
};

export const updateRentalShopSectionSkiSchool = (
  id: string,
  data: {
    title?: string;
    subtitle?: string;
    image?: {
      public_id: string;
      url: string;
    };
  }
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateRentalShopSectionEndpoint(id),
    data
  );

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateRepairSectionSkiSchool = (
  id: string,
  data: {
    title?: string;
    subtitle?: string;
    image?: {
      public_id: string;
      url: string;
    };
  }
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateRepairSectionEndpoint(id),
    data
  );

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateTeamSection = (
  data: {
    sectionTitle?: string;
    itemTitle?: string;
    subtitle?: string;
    image?: CloudinaryImageT;
  },
  itemId?: string
) => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateTeamSectionEndpoint(itemId),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== DELETE ========== //

export const deleteBannerSkiSchoolItem = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteBannerSkiSchoolItemEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};
