import { useEffect, useState } from "react";
import { Flex, Skeleton } from "antd";
import SEOSection from "../SEOSection/SEOSection";
import { getSEOData, updateSEOSettings } from "../../api/services/seoServices";
import { useApiRequest } from "../../hooks/useApiRequests";
import { GetSEOResT } from "../../api/responses/seoOptimizationResponses";

const SEOOptimization = () => {
  const [data, setData] = useState<GetSEOResT>();
  const [, makeGetSEOData] = useApiRequest<GetSEOResT>();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    makeGetSEOData(getSEOData(), (data) => {
      setData(data);
    });
  };

  if (!data)
    return (
      <Flex vertical gap={32}>
        <Skeleton.Input active />
        <Skeleton active paragraph={{ rows: 15 }} />
      </Flex>
    );

  return (
    <Flex vertical gap={32}>
      <h2>SEO Optimization</h2>
      <SEOSection
        data={data}
        getData={getData}
        updateService={updateSEOSettings}
      />
    </Flex>
  );
};

export default SEOOptimization;
