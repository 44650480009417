export const getPriceManagementEndpoint = () => {
  return `/pricemanagement`;
};

export const putPriceManagementIndividualSkiEndpoint = (id: string) => {
  return `/pricemanagement/individualski/${id}`;
};

export const putPriceManagementIndividualSnowboardEndpoint = (id: string) => {
  return `/pricemanagement/individualsnowboard/${id}`;
};

export const putPriceManagementGroupSkiEndpoint = (id: string) => {
  return `/pricemanagement/groupski/${id}`;
};

export const putPriceManagementGroupSnowboardEndpoint = (id: string) => {
  return `/pricemanagement/groupsnowboard/${id}`;
};

export const putPriceManagementParaglidingEndpoint = (id: string) => {
  return `/pricemanagement/paragliding/${id}`;
};

export const putPriceManagementQuadBikeEndpoint = (id: string) => {
  return `/pricemanagement/quadbike/${id}`;
};

export const putPriceManagementHorseRidingEndpoint = (id: string) => {
  return `/pricemanagement/horseriding/${id}`;
};

export const putPriceManagementSnowMobileEndpoint = (id: string) => {
  return `/pricemanagement/snowmobile/${id}`;
};

export const putPriceManagementTransferEndpoint = (id: string) => {
  return `/pricemanagement/transfer/${id}`;
};
