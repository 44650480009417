import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import classes from "./login.module.css";
import { Input } from "antd";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          username,
          password,
        }
      );

      localStorage.setItem("token", response.data.token);
      navigate("/dashboard"); // Navigate to dashboard after successful login
    } catch (error) {
      console.error("Login failed:", error);
      setError("Error: Username or password not correct!");
    }
  };

  return (
    <div className={classes.main}>
      <div className={classes.wrapper}>
        <img
          src={process.env.PUBLIC_URL + "/images/logo-dark.png"}
          alt="thumbnail"
        />
        <div className={classes.heading}>
          <h2>Welcome!</h2>
          <p>Sign In to your Admin account</p>
        </div>
        <form onSubmit={handleLogin} className={classes.form}>
          <Input
            className={classes.inputfield}
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input.Password
            className={classes.inputfield}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <p className={classes.error}>{error}</p>}
          <button type="submit" className={classes.btn}>
            Authorization
          </button>
        </form>
        <footer>
          Created by{" "}
          <a href="https://www.xcode.ge/" target="_black">
            Xcode International
          </a>
        </footer>
      </div>
    </div>
  );
};

export default Login;
