import { Button, Flex } from "antd";
import MediaCard from "../../../MediaCard/MediaCard";
import MediaCardsWrapper from "../../../MediaCardsWrapper/MediaCardsWrapper";
import { useState } from "react";
import MediaEditModal from "../../../MediaEditModal/MediaEditModal";
import { MediaDataT } from "../../../../types/mediaType";
import {
  createHomePageBanner,
  deleteHomePageBanner,
  updateHomePageBanner,
} from "../../../../api/services/homePageServices";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import { BannerProps } from "../../../../types/component-types/homepageProps";
import { cloudinaryUpload } from "../../../../lib/cloudinaryUpload";
import useMessages from "../../../../hooks/useMessages";
import { messageResT } from "../../../../api/responses";
import BannerResolutionWarning from "../../../../components/BannerResolutionWarning/BannerResolutionWarning";
import { MediaCreateModal } from "src/components/MediaCreateModal/MediaCreateModal";
import { deleteModal } from "src/components/DeleteModal";

const BannerSection: React.FC<BannerProps> = ({ bannerList, getData }) => {
  const [editBannerDetail, setEditBannerDetail] = useState<MediaDataT>();
  const [isEditBannerModalOpen, setIsEditBannerModalOpen] =
    useState<boolean>(false);
  const [isCreateBannerModalOpen, setIsCreateBannerModalOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [, makeRequest] = useApiRequest<messageResT>();

  const { error, success, contextHolder } = useMessages();

  const showEditBannerModal = (id: string) => {
    setIsEditBannerModalOpen(true);
    const editableBanner = bannerList.find((item) => item._id === id);
    setEditBannerDetail(editableBanner);
  };

  const handleCreateModalOpen = () => {
    setIsCreateBannerModalOpen(true);
    setEditBannerDetail(undefined);
  };

  const handleCancelCreateModalOpen = () => {
    setEditBannerDetail(undefined);
    setIsCreateBannerModalOpen(false);
  };

  const handleCreateBanner = () => {
    if (editBannerDetail) {
      try {
        makeRequest(
          createHomePageBanner({
            ...editBannerDetail,
          }),
          (e) => {
            setEditBannerDetail(undefined);
            setIsCreateBannerModalOpen(false);
            success(e.message);
            getData();
          }
        );
      } catch (err) {
        error("Something went wrong");
      }
    }
  };

  const handleOkEditBanner = () => {
    if (editBannerDetail) {
      try {
        makeRequest(
          updateHomePageBanner(
            {
              title: editBannerDetail?.title,
              subtitle: editBannerDetail?.subtitle,
              image: editBannerDetail?.image,
            },
            editBannerDetail._id
          ),
          (e) => {
            setEditBannerDetail(undefined);
            setIsCreateBannerModalOpen(false);
            success(e.message);
            getData();
          }
        );
      } catch (e) {
        error("Something went wrong");
      }
    }
  };

  const handleDelete = (id: string) => {
    try {
      makeRequest(deleteHomePageBanner(id), (e) => {
        success(e.message);
        getData();
      });
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleDeleteBanner = (id: string) => {
    deleteModal(() => handleDelete(id), "Are you sure to delete banner?");
  };

  const handleCancelEditBanner = () => {
    setIsEditBannerModalOpen(false);
  };

  const handleBannerFieldChange = async (fieldName: string, value: string) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }
    setEditBannerDetail(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as MediaDataT)
    );
    setLoading(false);
  };

  return (
    <>
      {contextHolder}

      <section>
        <Flex vertical gap={12} align="start">
          <h2>Banner</h2>
          <BannerResolutionWarning />
          <Button onClick={handleCreateModalOpen}>Add Banner</Button>

          <MediaCreateModal
            inputValues={editBannerDetail}
            isModalOpen={isCreateBannerModalOpen}
            handleOk={handleCreateBanner}
            handleCancel={handleCancelCreateModalOpen}
            handleFieldChange={handleBannerFieldChange}
            customTitle="Create Banner"
          />

          <MediaCardsWrapper>
            {bannerList.map((item) => (
              <MediaCard
                key={item._id}
                data={item}
                onEdit={() => showEditBannerModal(item._id)}
                onDelete={() => handleDeleteBanner(item._id)}
              />
            ))}
          </MediaCardsWrapper>
        </Flex>

        <MediaEditModal
          isMediaEditModalOpen={isEditBannerModalOpen}
          handleOk={handleOkEditBanner}
          handleCancel={handleCancelEditBanner}
          handleFieldChange={handleBannerFieldChange}
          mediaDetails={editBannerDetail}
          isLoading={loading}
        />
      </section>
    </>
  );
};

export default BannerSection;
